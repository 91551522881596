import {Link} from "gatsby";
import React, {FC} from "react";
import Helmet from "react-helmet";
import {gettingStartedSlug} from "../../helpers/slug-helper";
import GitHubButton from "../gh-button";

const HeroSection: FC = () => (
    <>
        <Helmet>
            <script async defer src="https://buttons.github.io/buttons.js"></script>
        </Helmet>
        <header className="bg-gray-900 text-center py-20 px-4">
            <h1 className="text-gray-400 font-serif text-5xl p-0 mb-12">{"gridl"}</h1>
            <p className="text-white font-light text-3xl my-8">{"A modern ES6 toolbox for grid-based data."}</p>
            <div className="flex justify-center items-center space-x-4">
                <Link to={gettingStartedSlug()} className="btn-primary">
                    {"Get started"}
                </Link>
                <GitHubButton />
            </div>
        </header>
    </>
);

export default HeroSection;
