import React, {FC} from "react";
import HeroSection from "../components/sections/hero";
import Layout from "../components/layout/layout-default";
import TeaserSection from "../components/sections/teaser";
import {graphql} from "gatsby";
import Seo from "../components/seo";
import CodeExampleSection from "../components/sections/code-example";

const IndexPage: FC = () => (
    <Layout>
        <Seo description="Open source JavaScript ES6 library written in Typescript providing helper functions for grid-based or matrix data." />
        <HeroSection />
        <TeaserSection teasers={teasers} />
        <CodeExampleSection examples={codeExamples} />
    </Layout>
);

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
                author {
                    name
                    web
                }
                gridl {
                    latestVersion
                }
            }
        }
    }
`;

const codeExamples = [
    {
        title: "In a browser",
        example: {
            code: `<script src="https://cdn.jsdelivr.net/npm/gridl@latest/_umd/index.min.js"></script>`,
        },
    },
    {
        title: "Using npm",
        example: {
            code: `$ npm install gridl`,
        },
    },
    {
        title: "Using yarn",
        example: {
            code: `$ yarn add gridl`,
        },
    },
    {
        title: "ES6 modules",
        example: {
            language: "javascript",
            code: `import {createGrid} from "gridl/core";\nimport {mirrorHorizontally} from "gridl/transformers";`,
        },
    },
    {
        title: "Node.js",
        example: {
            language: "javascript",
            code: `const {createGrid, mirrorHorizontally} = require("gridl/_umd)";`,
        },
    },
];

const teasers = [
    {
        title: "Selectors",
        text: "Easily select cells, columns, rows, sub grids or neighbouring cells with selector functions.",
    },
    {
        title: "Transformers",
        text: "Do all sorts of data transformations on your grid like adding, removing, rotating, swapping, mirroring and more.",
    },
    {
        title: "Traversable",
        text:
            "Traverse over your grid in variety of ways. Choose from a predefined set of walker functions or just come up with your own one.",
    },
    {
        title: "ES6 Modules",
        text:
            "Use only the parts you need and keep your bundle size as small as possible with treeshaking. Alternatively, there is also a UMD bundle.",
    },
    {
        title: "Immutable",
        text:
            "The grid data structures is immutable. This aligns well with applications using a flux architecture like React and Redux.",
    },
    {
        title: "Typed",
        text: "Since gridl is written in TypeScript it naturally comes with complete and up-to-date type definitions.",
    },
];

export default IndexPage;
