import React, {FC, useEffect, useState} from "react";

const GitHubButton: FC = () => {
    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
        }
    }, [isFirstRender, setIsFirstRender]);

    return isFirstRender ? null : (
        <span className="btn-github">
            <a
                className="github-button"
                href="https://github.com/klattiation/gridl"
                rel="noreferrer noopener"
                data-size="large"
                data-show-count="true"
                aria-label="Star klattiation/gridl on GitHub"
            >
                Star
            </a>
        </span>
    );
};

export default GitHubButton;
