import React, {FC} from "react";

interface TeaserProps {
    title: string;
    text: string;
}

interface TeaserSectionProps {
    teasers: TeaserProps[];
}

const TeaserSection: FC<TeaserSectionProps> = ({teasers}) => (
    <section className="text-gray-300 py-20">
        <div className="container mx-auto px-4 lg:px-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-16 md:gap-16">
            {teasers.map(({title, text}) => (
                <div key={title} className="text-center">
                    <h3 className="mb-2 text-2xl">{title}</h3>
                    <p className="text-lg">{text}</p>
                </div>
            ))}
        </div>
    </section>
);

export default TeaserSection;
