import React, {FC} from "react";
import Code, {CodeProps} from "../code";

interface CodeExampleProps {
    title: string;
    example: CodeProps;
}

interface CodeExampleSectionProps {
    examples: CodeExampleProps[];
}

const CodeExampleSection: FC<CodeExampleSectionProps> = ({examples}) => (
    <section className="container mx-auto mt-8 mb-16 px-4 lg:px-0 text-gray-300">
        <h2>Installation</h2>
        {examples.map(({title, example}) => (
            <div key={title} className="my-8">
                <h3 className="text-base font-sans text-gray-300">{title}</h3>
                <Code {...example} />
            </div>
        ))}
    </section>
);

export default CodeExampleSection;
